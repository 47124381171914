export default {
    name: 'Evengelio',

    components: {},
    data() {
        return {
            selectedItem: 1,
            spinner: false,
            result:[],
            reading:{
                first:{
                    title: "",
                    verse: "",
                    text: ""
                },
                second:{
                    title: "",
                    verse: "",
                    text: ""
                }
            },
            gospel:{
                title: "",
                text: ""
            },
            pope:{
                title:"",
                text:""
            },
            source:"",
            show1: false,
            show2: true,
            show3: false
        }
    },
    beforeMount() {
        this.$store.dispatch('setSpinner', true);
    },
    async mounted() {
        try{
            await Promise.all([
                this.$store.dispatch("fetchBibleToday")
            ])
            this.result = this.$store.getters.getBible;
            localStorage['reading'] = this.result.reading || {
                first:{
                    title: "",
                    verse: "",
                    text: "Texto no disponible hoy"
                }
            };
            this.reading = this.result.reading || {
                first:{
                    title: "",
                    verse: "",
                    text: "Texto no disponible hoy"
                }
            };
            localStorage['gospel'] = this.result.gospel || {
                title: "",
                text: "Texto no disponible hoy"
            };
            this.gospel = this.result.gospel  || {
                title: "",
                text: "Texto no disponible hoy"
            };
            localStorage['pope'] =this.result.pope || {
                title:"",
                text:"Texto no disponible hoy"
            };
            this.pope = this.result.pope || {
                title:"",
                text:"Texto no disponible hoy"
            };
            localStorage['source'] =this.result.source;
            this.source = this.result.source;
        } catch(error){
            console.log(error);
            alert("Se ha producido un error al cargar la página. Compruebe su conexión a internet e inténtelo de nuevo. Si el error persiste, póngase en contacto con el Ministerio de Comunicación.")
        } finally {
            this.$store.dispatch('setSpinner', false);
        }
    },
    computed: {
        
    },
    methods: {
       
    }
}